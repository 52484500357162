<template>
    <BackNavBar :title="$t('order_result_page')"></BackNavBar>
    <div class="order-image" style="padding: 10%; background-color: white">
        <van-image :src="img_url"></van-image>
        <van-button
            class="paid"
            type="primary"
            @click="goBack"
        >
            <p>{{ $t('paid') }}</p>
        </van-button>
    </div>
</template>

<script>

import BackNavBar from "@/components/BackNavBar";

export default {
    name: "OrderResultView",
    components: {
        BackNavBar,
    },
    data() {
        return {
            img_url: null,
        }
    },
    methods: {
        goBack() {
            this.$router.back();
        },
    },
    mounted() {
        this.img_url = this.$route.query.img_url;
    },
}
</script>
<style lang="scss">
    .order-image .paid {
        border-radius: 12px;
    }
</style>
