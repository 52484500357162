import {createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw} from 'vue-router'
import ShangDaiView from '../views/ShangDaiView.vue'
import CateGoryView from '../views/CateGoryView.vue'
import LoginView from '../views/LoginView.vue'
import UserAgreement from '../views/UserAgreement.vue'
import PrivacyView from '../views/Privacy.vue'
import UserInfo from '../views/UserInfo.vue'
import EditInfo from '../views/EditInfo.vue'
import SearchPage from '../views/SearchPage.vue'
import DetailPage from '../views/DetailPage.vue'
import siteRules from '../views/SiteRuleView.vue'
import RechargeView from '../views/RechargeView.vue'
import ConfirmSaleView from '../views/ConfirmSaleView.vue'
import BillingRecords from '../views/BillingRecords.vue'
import AboutNaughtyStoneView from '../views/AboutNaughtyStone.vue'
import OrderView from '../views/OrderView.vue'
import ProtocolView from '../views/ProtocolView.vue'
import OrderResult from '../views/OrderResultView.vue'
import i18n from '@/lang/index';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'index',
        component: () => import(/* webpackChunkName: "about" */ '../views/IndexView.vue'),
        meta: {
            title: i18n.global.t('platform_name'),
        }
    },
    {
        path: '/shangdai',
        name: 'shangdai',
        component: ShangDaiView,
        meta: {
            title: i18n.global.t('package'),
        }
    },
    {
        path: '/category',
        name: 'category',
        component: CateGoryView,
        meta: {
            title: i18n.global.t('category'),
        }
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: {
            title: i18n.global.t('login'),
        }
    },
    {
        path: '/useragreement',
        name: 'useragreement',
        component: UserAgreement,
        meta: {
            title: i18n.global.t('user_agreement'),
        }
    },
    {
        path: '/privacy',
        name: 'privacyview',
        component: PrivacyView,
        meta: {
            title: i18n.global.t('privacy'),
        }
    },
    {
        path: '/userinfo',
        name: 'userinfo',
        component: UserInfo,
        meta: {
            title: i18n.global.t('me'),
        }
    },
    {
        path: '/editinfo',
        name: 'editinfo',
        component: EditInfo,
        meta: {
            title: i18n.global.t('edit_info'),
        }
    },
    {
        path: '/search',
        name: 'search',
        component: SearchPage,
        meta: {
            title: i18n.global.t('search'),
        }
    },
    {
        path: '/detail/:id/:type',
        name: 'detailItem',
        component: DetailPage,
        meta: {
            title: i18n.global.t('detail'),
        }
    },
    {
        path: '/siterules',
        name: 'siteRules',
        component: siteRules,
        meta: {
            title: i18n.global.t('platform_protocol'),
        }
    },
    {
        path: '/recharge',
        name: 'recharge',
        component: RechargeView,
        meta: {
            title: i18n.global.t('recharge'),
        }
    },
    {
        path: '/orderresult',
        name: 'orderresult',
        component: OrderResult,
        meta: {
            title: i18n.global.t('order_result_page'),
        }
    },
    {
        path: '/confirmsale',
        name: 'confirmsale',
        component: ConfirmSaleView,
        meta: {
            title: i18n.global.t('confirm_sale_information'),
        }
    },
    {
        path: '/billingrecords',
        name: 'billingrecords',
        component: BillingRecords,
        meta: {
            title: i18n.global.t('account_detail_list'),
        }
    },
    {
        path: '/aboutnaughtystone',
        name: 'aboutnaughtystone',
        component: AboutNaughtyStoneView,
        meta: {
            title: i18n.global.t('about_stone_coin'),
        }
    },
    {
        path: '/order/:tab',
        name: 'order',
        component: OrderView,
        meta: {
            title: i18n.global.t('my_order'),
        }
    },
    {
        path: '/rule',
        name: 'rule',
        component: ProtocolView,
        meta: {
            title: i18n.global.t('platform_rules'),
        }
    },

]

const router = createRouter({
    history: process.env.NODE_ENV === 'development'
        ? createWebHashHistory(process.env.BASE_URL)
        : createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to) => {
    if (typeof (to.meta?.title) === 'string') {
        document.title = to.meta?.title;
    }
});

export default router
