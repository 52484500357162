export default {
    "index": "首页",
    "package": "背包",
    "me": "我的",
    "category": "分 类",
    "search_product": "搜索心仪的赏品",
    "all": "全部",
    "buy": "求购",
    "sell": "出售",
    "account_detail_list": "账户明细",
    "go_back": "返回",
    "balance": "余额",
    "date_time": "时间",
    "operate": "操作",
    "detail": "详情",
    "loading": "加载中...",
    "load_finished": "没有更多了",
    "load_error": "请求失败，点击重新加载",
    "operation_failure": "操作失败",
    "account_frozen": "账户冻结",
    "register_failure": "注册失败",
    "please_fill_recharge_amount": "请填写充值数量",
    "exceeded_total_withdrawal_limit": "已超出今日提现总额度",
    "invalid_goods": "不存在交易商品",
    "can_not_buy_self_goods": "自己的商品无法购买",

    "search": "搜索",
    "search_history": "历史搜索",
    "search_hot": "热门搜索",
    "recharge": "充值",
    "hot": "热度",
    "new": "最新",
    "trade_deadline": "交易截止时间",
    "trade_post_time": "交易发布时间",
    "buy_now": "立即购买",
    "buy_confirm": "确认购买",
    "recharge_success_msg": "支付成功后会立即成交",
    "trade_close_msg": "若交易关闭/发生退款，退款到账时间为",
    "hour": "小时",
    "order_amount": "订单金额",
    "agree": "同意",
    "next": "下一步",
    "user_service_agreement": "用户服务协议",
    "coin_payment": "金币支付",
    "coin_recharge": "金币充值",
    "currency_vietnam": "越南盾",
    "coin": "金币",
    "recharge_now": "立即充值",
    "platform_rules": "平台规则",
    "got_time": "入手时间",
    "unpack": "前往开箱",
    "trading": "交易中",
    "selected": "已选择",
    "piece": "件",
    "maximum_selection": "最多选择",
    "select": "选择",
    "up_sell": "最多出售",
    "select_product": "请选择要卖出的商品",
    "confirm_sale_information": "确认出售信息",
    "estimated_total_transaction_price": "本交易所有商品预期合计成交价格",
    "sale_quantity": "出售数量",
    "enter_sale_quantity": "请输入出售数量",
    "waring_sell_num_text": "出售数量不得大于该商品总数量",
    "sum": "总数",
    "enter_total_expected_transaction_price": "输入合计预期成交价格",
    "rule_description": "规则说明",
    "validity_sale_information": "出售信息有效期",
    "confirm_sale_price": "买家付款后将立即成交，请您在确认出售信息前再次确认出售金额",
    "service_charge_msg": "支付服务商将在交易过程中收取成交金额的 {x}% 作为支付服务费",
    "enter_correct_price": "请输入正确的价格",
    "confirm_sale_price_again": "买家付款后将立即成交，请您在确认出售前反复确认出售金额",
    "repeat_confirm_sale_price": "二次确认预期成交价格",
    "phone": "手机号",
    "unbind": "未绑定",
    "bind_phone": "绑定手机",
    "my_account": "我的账户",
    "withdraw": "提现",
    "exchage_stone_coin": "兑换成淘气石",
    "stone_coin": "淘气石",
    "about_stone_coin": "关于淘气石",
    "my_sale": "我的出售",
    "trade_record": "交易记录",
    "service_center": "客服中心",
    "platform_protocol": "平台协议",
    "version": "版本号",
    "log_out": "退出登录",
    "login": "登录",
    "sign_in": "注册",
    "your_account": "你的账号",
    "please_enter": "请输入",
    "verification_code": "验证码",
    "send_verification_code": "发送验证码",
    "wait": "等待",
    "second": "秒",
    "read_msg": "我已阅读并同意",
    "user_agreement": "用户协议",
    "privacy": "隐私协议",
    "modify_personal_information": "修改个人信息",
    "save": "保存",
    "sell_goods": "出售商品",
    "buy_goods": "购买商品",
    "bank_transfer": "转账银行卡",
    "exchange_stone_coin": "兑换淘气石",
    "check": "审核",
    "refund": "退款",
    "withdrawal_method": "请选择提现方式",
    "bank_transfer_method": "银行卡转账",
    "bound": "已绑定",
    "bind": "绑定",
    "transfer_bank_account": "转移到银行卡账户",
    "bank_card": "银行卡",
    "current_withdrawable_balance": "当前可提现余额",
    "please_enter_amount": "请输入金额",
    "minimum": "最低",
    "attention": "注意",
    "maximum_withdrawal_day": "单日最多提现",
    "withdraw_remaining_amount_today": "今日提现剩余额度",
    "bank_username": "银行卡开户名字",
    "bank_account": "银行卡卡号",
    "bank_name": "开户银行名字",
    "name": "姓名",
    "account_number": "卡号",
    "bank": "银行",
    "money_to_stone_coin": "金币兑换淘气石",
    "irreversible": "不可逆转",
    "remind": "提醒",
    "irreversible_msg": "本次兑换是不可逆行为，金币兑换为淘气石后将不能换回金币、不可提现",
    "explain": "说明",
    "explain_1": "金币兑换淘气石为单向操作",
    "explain_2": "即金币可以兑换为淘气石",
    "explain_3": "淘气石不可兑换为金币",
    "explain_4": "且淘气石不可提现",
    "current_exchange_quantity": "当前可兑换",
    "please_enter_stone_coin_quantity": "请输入兑换淘气石数量",
    "exchange_quantity_day": "每天可兑换",
    "exchanged_current": "当前已兑换",
    "times": "次",
    "go_to_magic_box": "前往淘气魔盒",
    "my_order": "我的订单",
    "selling": "出售中",
    "complete_transaction": "交易完成",
    "cancel_trade": "取消交易",
    "service_work_time": "客服工作时间",
    "service_vip": "专属客服",
    "service_common": "常规客服",
    "close": "关闭",
    "confirm": "确认",
    "cancel": "取消",
    "short_coin": "您的金币不足",
    "post_success": "发布成功",
    "enter_confirm_price": "请输入二次确认价格",
    "different_price": "二次确认价格与第一次价格不符",
    "withdrawal_channel_maintenance": "提现渠道维护中",
    "enter_correct_phone_number": "请输入正确的手机号码",
    "enter_phone_number": "请输入手机号码",
    "enter_verification_code": "请输入验证码",
    "please_agree_user_agreement": "请先同意用户协议",
    "verification_code_expired": "验证码已过期",
    "please_bind_bank_card": "请先绑定银行卡",
    "please_enter_withdrawal_amount": "请输入提现金额",
    "withdrawal_amount_cannot_be_less": "提现金额不能小于",
    "withdrawal_amount_cannot_be_over":"提现金额不能大于",
    "less_balance": "您的余额不足",
    "withdrawal_day_only": "每天只能提现",
    "please_enter_exchange_quantity": "请输入兑换数量",
    "exchange_amount_cannot_be_less": "兑换金额不能小于",
    "exchange_day_only": "每天只能兑换",
    "platform_name": "旧货交易中心",
    "finally_price": "一口价",
    "goods_quantity": "商品数量",
    "edit_info": "编辑资料",
    "success": "成功",
    "nickname":"昵称",
    "agent_recharge":"代理充值",
    "system_gift":"系统赠送",
    "recharge_method":"支付方式",
    "please_login_again":"请重新登录",
    "what_is_naughty_stone":"什么是淘气石？",
    "go_to_magic_box_title":"淘气魔盒",
    "go_to_magic_box_content":"魔盒拆不完，惊喜拆不断。",
    "pc_alert":"请使用移动设备打开网页",
    "choose_product":"选择商品",
    "recharge_channel_close":"充值渠道维护中",
    "withdraw_channel_close":"提现渠道维护中",
    "exchange_channel_close":"兑换渠道维护中",
    "choose_bank":"请选择银行",
    "invalid_recharge_channel":"支付渠道不存在",
    "recharge_failed":"支付失败",
    "complete": "已完成",
    "failure": "失败",
    "sold": "已出售",
    "bought": "已购买",
    "account_detail": "帐户变动详情",
    "exist_withdrawal": "已有提现申请在审核中",
    "withdrawal_refund": "提现失败退款",
    "manual_deduction": "系统扣款",
    "refuse": "拒绝",
    "exchange_refund": "兑换失败退款",
    "arrival_time": "5分钟-24小时之内到账",
    "waring": "警告",
    "waring_price_text": "价格必须等于或大于 1",
    "new_guide": "新手引导",
    "suggested_transaction_price": "建议成交价格",
    "current_balance": "账户金币",
    "order_result_page": "下单结果页",
    "paid": "已付款",
}