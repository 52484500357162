<template>
    <BackNavBar :title="$t('confirm_sale_information')"></BackNavBar>
    <div class="confirm-sale">
        <p class="quantity-item">{{ $t('all') }} <b>{{ Object.keys(objItem).length }}</b> {{ $t('piece') }}</p>
        <van-swipe class="my-swipe" lazy-render>
            <van-swipe-item v-for="(item) in objItem" :key="item">
                <div class="slide-item">
                    <div class="img">
                        <img class="slide-img" :src="item.goodsInfo.img" @click="expandImage(item.goodsInfo.img)"/>
                    </div>
                    <div class="description-tag">
                        <p>{{ item.goodsInfo.name }} × {{ item.ItemNum }}</p>
                    </div>
                    <div class="num-input">
                        <span class="num-input-text">{{ $t('sale_quantity') }}</span>
                        <van-field
                            class="input-num"
                            v-model="numArr[item.ItemId]"
                            type="number"
                            :placeholder="$t('enter_sale_quantity')"
                            @update:model-value="checkNum($event, item.ItemId)"
                            center
                        />
                    </div>
                    <p class="error-text-num" v-show="invalidNumArr[item.ItemId]">{{ $t('waring') }}:
                        {{ $t('waring_sell_num_text') }}</p>
                </div>
            </van-swipe-item>
        </van-swipe>
        <div class="price-input">
            <p>{{ $t('estimated_total_transaction_price') }}({{ $t('coin') }})</p>
            <van-field
                class="input-price"
                v-model="price"
                type="number"
                :placeholder="$t('enter_total_expected_transaction_price')"
                @update:model-value="checkPrice"
                center
            />
            <p class="recommend-text">{{ $t('suggested_transaction_price') }}: {{ suggestedTransactionPrice }} {{ $t('coin')}}</p>
            <p class="error-text" v-show="invalid">{{ $t('waring') }}: {{ $t('waring_price_text') }}</p>
        </div>
        <div class="payment-rule">
            <p class="rule-title">{{ $t('recharge_method') }}</p>
            <ul>
                <li><a>1.</a> {{ $t('validity_sale_information') }}: {{ trade_time }} {{ $t('hour') }}</li>
                <li><a>2.</a> {{ $t('confirm_sale_price') }}</li>
                <li><a>3.</a> {{ $t('service_charge_msg', {x: sale_fee}) }}</li>
            </ul>
        </div>
        <div class="checkbox">
            <van-checkbox
                v-model="isAgree"
                icon-size="20px"
                :checked-color="checkedColor"
            >
                {{ $t('agree') }}
                <router-link to="/siterules" style="">( {{ $t('user_service_agreement') }} )</router-link>
            </van-checkbox>
        </div>
        <van-button class="confirm-btn"
                    :style="{backgroundColor: (isAgree === true && price !== '' && invalid == false) ? '#D93D23' :  '#B4B4B4'}"
                    @click="checkAgreement()">
            <p>{{ $t('confirm') }}</p>
        </van-button>
    </div>
    <!--    <span>{{goods_data}}</span>-->
    <!--sale pop up-->
    <van-popup class="popup" position="bottom" @click-overlay="showConfirmSale = false" v-model:show="showConfirmSale"
               closeable round>
        <p class="popup-title">{{ $t('confirm_sale_information') }}</p>
        <img class="popup-slide-img" src="@/assets/img_icon/slide.png" alt/>
        <p class="popup-subtitle">{{ $t('confirm_sale_price') }}</p>
        <div class="price">
            <p>{{ goodPrice }}</p>
        </div>
        <p class="red-text">
            {{ $t('service_charge_msg', {x: sale_fee}) }}
        </p>
        <van-button class="sale-btn" @click="sellGoods()" :loading="loading">
            <p>{{ $t('confirm') }}</p>
        </van-button>
    </van-popup>
    <!--sale overlay-->
    <van-overlay class="overlay-img" :show="showImg === true" @click="showImg = false">
        <div class="wrapper-Image">
            <img :src="clickedImg" alt/>
        </div>
    </van-overlay>
</template>

<script>
import {NavBar} from "vant";
import {ref} from 'vue'
import BackNavBar from "@/components/BackNavBar";
import {sellGoods} from "@/api/backpack";

export default {
    name: "ConfirmSaleView",
    components: {
        BackNavBar,
        [NavBar.name]: NavBar,
    },
    data() {
        return {
            loading: false,
            sale_fee: 0,
            trade_time: 0,
            checkedColor: process.env.VUE_APP_CHECKEDBGCOLOR ? process.env.VUE_APP_CHECKEDBGCOLOR : '',
        };
    },
    setup() {
        const showConfirmSale = ref(false);
        const isChecked = ref(false);
        const isAgree = ref(true);
        const showImg = ref(false);
        const clickedImg = ref('');
        const price = ref('');
        const currency = process.env.VUE_APP_CURRENCY_SYMBOL ? process.env.VUE_APP_CURRENCY_SYMBOL : '$';
        const expandImage = (image) => {
            showImg.value = true;
            clickedImg.value = image;
        };
        const objItem = ref({});
        const paramItem = ref({});
        const numArr = ref({});
        const numTotalArr = ref([]);
        const goods_data = ref([]);
        const invalid = ref(false);
        const invalidNumArr = ref([]);
        const goodsPriceArr = ref([]);
        return {
            currency,
            showConfirmSale,
            isChecked,
            isAgree,
            showImg,
            expandImage,
            clickedImg,
            price,
            objItem,
            paramItem,
            goods_data,
            invalid,
            invalidNumArr,
            numTotalArr,
            numArr,
            goodsPriceArr,
        };
    },
    mounted() {
        this.sale_fee = this.$route.query.sale_fee;
        this.trade_time = this.$route.query.trade_time;
        this.objItem = JSON.parse(decodeURIComponent(this.$route.query.obj));
        console.log('objItem', this.objItem);
        Object.keys(this.objItem).forEach((key) => {
            this.paramItem = {
                storehouse_id: this.objItem[key].storehouseId,
                goods_id: this.objItem[key].goodsInfo.id,
                item_id: this.objItem[key]['ItemId'],
                num: 0,
            }
            this.goods_data.push(this.paramItem);
            this.goodsPriceArr.push({
                item_id: this.objItem[key]['ItemId'],
                goods_price: this.objItem[key].goodsInfo.coin,
            });
            this.numTotalArr[this.objItem[key]['ItemId']] = this.objItem[key]['ItemNum'];
            this.numArr[this.objItem[key]['ItemId']] = null;
            this.invalidNumArr[this.objItem[key]['ItemId']] = false;
        })
    },
    methods: {
        onClickLeft() {
            this.$router.back();
        },
        checkAgreement() {
            if (this.isAgree === false || this.price === '') {
                console.log('Agreement required!')
            } else {
                this.showConfirmSale = true;
            }
        },
        sellGoods() {
            this.loading = true;
            this.goods_data.forEach((item, key) => {
                // 如果 可售数量小于输入数量
                if (Number(this.numArr[item.item_id]) > Number(this.numTotalArr[item.item_id])) {
                    this.invalidNumArr[item.item_id] = false;
                    this.loading = false;
                    return false;
                }
                this.goods_data[key].num = this.numArr[item.item_id]
            })

            let param = {
                goods_data: JSON.stringify(this.goods_data),
                price: this.price
            };
            sellGoods(param).then(res => {
                this.loading = false;
                if (res.code == 200 && res.msg == 'success') {
                    this.$router.push({path: '/shangdai'});
                }
            }).catch((e) => {
                console.log(e);
                this.loading = false;
            })
        },
        checkPrice() {
            Number(this.price) < 1 ? this.invalid = true : this.invalid = false;
        },
        checkNum(num, ItemId) {
            if (Number(num) < 1 || Number(num) > Number(this.numTotalArr[ItemId])) {
                this.invalidNumArr[ItemId] = true
            } else {
                this.invalidNumArr[ItemId] = false;
            }
        }
    },
    computed: {
        goodPrice() {
            return (this.price.charAt(0) == 0) ? this.price.substring(1) : this.price;
        },
        suggestedTransactionPrice() {
            let res = 0;

            this.goodsPriceArr.forEach((item) => {
                if (Object.prototype.hasOwnProperty.call(this.numArr, item.item_id)) {
                    res += Number(item.goods_price) * Number(this.numArr[item.item_id]);
                }
            })
            return res;
        },
    }
}
</script>

<style lang="scss" src="@/styles/confirmSale.scss" scoped></style>
<style lang="scss">
.confirm-sale {
    .my-swipe {
        .van-swipe__indicator {
            background-color: #4c4c4c;
        }
    }
}
</style>